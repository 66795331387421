import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import BannerContact from "../components/contact/banner/banner.component"
import ContactSecond from "../components/contact/contact,secondsection"

const Contact = () => {
  return (
    <div>
      <Layout>
        <Seo title="Contact Us" />
        <BannerContact />
        <ContactSecond />
      </Layout>
    </div>
  )
}

export default Contact
