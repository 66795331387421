import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ContactSecond = () => {
  return (
    <>
      <div className="section sub-container">
        <div className="flex space-between align-center">
          <h3 style={{ maxWidth: "50%" }}>Let’s see what we can do for you</h3>
          <div style={{ width: "50%" }}>
            <StaticImage src="../../images/waves.png" alt="waves" />
          </div>
        </div>
        <div className="flex space-between">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel
          </p>
          <p className="pl-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel
          </p>
        </div>
        <div className="mt-4">
          <StaticImage src="../../images/girl.png" alt="girl" />
        </div>
      </div>
    </>
  )
}

export default ContactSecond
